import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import ViberModal from "elements/ViberModal"
import ButtonGroup from "../../../Elements/ButtonGroup"
import ViberSlidingModal from "../../../Elements/ViberSlidingModal"

import HealthSurveySummary from "./HealthSurveySummary"
import ConsentAndAuthorization from "./ConsentAndAuthorization"
import MedicinesOrderedSummary from "./MedicinesOrderedSummary"
import UploadDocumentsSummary from "./UploadDocumentsSummary"
import PersonalInformationSummary from "./PersonalInformationSummary"

import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"
import { handleSubmitMedensureRequest } from "../services/handleSubmitMedensureRequest"
import { useAirtableMEFormFields } from "../../MedEnsureContext/hooks/useAirtableMEFormFields"
import { flattenNode } from "../../../../services/arrays"
import { AppContext } from "../../../../context/AppContext"

const Summary = ({ pageContext }) => {
  const { state, dispatch } = useContext(AppContext)
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  const [loading, setLoading] = useState(false)

  let formFields = useAirtableMEFormFields()
  formFields = flattenNode(formFields?.allAirtableallMedEnsureFormFields)

  const handleSuccessCallback = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isCard: true,
        heading: " ",
        headerClass: "has-background-viber",
        hideCloseButton: true,
        hideOnMobile: true,
        content: (
          <ViberModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: medEnsureState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        isCard: true,
        content: (
          <ViberSlidingModal
            callback={() => {
              navigate(pageContext.nextPath, {
                state: {
                  email: medEnsureState?.email,
                },
              })
              setLoading(false)
            }}
            dispatch={dispatch}
          />
        ),
      },
    })
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    if (medEnsureState && medEnsureState.medEnsureReferralSources) {
      medEnsureState.medEnsureReferralSources = medEnsureState?.medEnsureReferralSources?.join(
        ", "
      )
      if (!medEnsureState.medEnsureReferralSources.includes("Others")) {
        medEnsureState.otherMedEnsureReferralSources = "N/A"
      }
    }
    await handleSubmitMedensureRequest({
      callback: handleSuccessCallback,
      errorCallback: handleErrorCallback,
      medEnsureState,
      state,
      formFields,
      files: medEnsureState?.documents,
    })
  }

  useEffect(() => {
    medEnsureDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered desktop={6}>
        <PersonalInformationSummary
          route={`/${pageContext?.module?.name}/request/employee-information`}
          data={medEnsureState}
        />
        <HealthSurveySummary
          route={`/${pageContext?.module?.name}/request/health-survey`}
          data={medEnsureState}
          flow="medensure_request"
        />
        <MedicinesOrderedSummary
          route={`/${pageContext?.module?.name}/request/order`}
          medicines={medEnsureState?.medicines}
        />
        <UploadDocumentsSummary
          route={`/${pageContext?.module?.name}/request/upload`}
          documents={medEnsureState?.documents}
        />
        <ConsentAndAuthorization
          handleOnSubmit={handleOnSubmit}
          flow="medensure"
        >
          <section className="message is-warning p-1 my-2 has-text-centered">
            Eligibility is not yet final until verified by our pharmacists via
            email.
          </section>
          <ButtonGroup
            back={{
              link: pageContext.backPath,
            }}
            next={{
              label: `Submit`,
              link: pageContext.nextPath,
              loading: loading,
              type: "submit",
              disabled:
                medEnsureState?.medicines?.length === 0 ||
                medEnsureState?.documents?.length === 0 ||
                medEnsureState?.agreeToMechanics?.length === 0 ||
                medEnsureState?.consentAndAuthorization.length < 3,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default Summary
