import { createZendeskBodyFields } from "./createZendeskBodyFields"

export const createZendeskTemplate = ({ state, formFields }) => {
  let moduleNames = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.module)
    ),
  ]
  let zendeskOrder = [
    ...new Set(
      formFields
        .filter((field) => field.zendeskOrder)
        .map((field) => field.zendeskOrder)
    ),
  ]

  moduleNames = moduleNames.map((item) => ({ module: item }))

  moduleNames.forEach((moduleName, index) => {
    let filteredFormFields = formFields.filter(
      (field) =>
        field.module === moduleName.module && field.showOnZendesk === true
    )

    filteredFormFields = filteredFormFields.map((field) => {
      return {
        name: field.name,
        type: field.type,
        zendeskKey: field.zendeskKey,
      }
    })

    moduleNames[index].fields = filteredFormFields
    moduleNames[index].fields.reverse()
  })

  moduleNames.forEach(
    (moduleName, index) => (moduleName.zendeskOrder = zendeskOrder[index])
  )

  moduleNames = moduleNames.sort((a, b) => a.zendeskOrder - b.zendeskOrder)

  let template = ""

  let header = `<strong style="color:#0d6d6e;"> MEDENSURE REQUEST </strong><br/><br/>`
  template = template + header

  moduleNames.map((module) => {
    template = template + `<strong><u>${module.module}</u></strong><br/><br/>`
    template =
      template +
      createZendeskBodyFields({
        module: module,
        state: state,
      })
    template = template + "<br/>"

    return null
  })

  let medicinesRequestBody = `<strong><u>Medicines Requested</u></strong><br/><br/>Total Ordered Medicines: ${
    state.medicines.length
  }<br/><br/>${state.medicines
    .map(
      (medicine) =>
        `Medicine: ${medicine.DrugName}<br/>Quantity: ${medicine.qty}<br/>Daily Intake: ${medicine.intake}`
    )
    .join("<br/><br/>")}<br/><br/>`

  let viberConsentBody = `<strong><u>Viber Consent</u></strong><br/><br/>I allow MedGrocer to reach out to me for outbound teleconsults and prescription renewal<br/>I allow MedGrocer to enroll me in the Viber community for future communication and announcements.
  `
  template = template + medicinesRequestBody + viberConsentBody
  template = template + "<br/>"

  return template
}
