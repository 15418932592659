import React from "react"
import _ from "lodash"
import classNames from "classnames"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import { formatString } from "services/general"
import healthMonitoringFields from "./utils/healthMonitoringFields.json"
import styles from "./utils/summary.module.scss"

/**
 *
 * @param {String} flow => medensure_request, medensure_pickup, ...
 */
const HealthSurveySummary = ({ route, data, flow }) => {
  const healthMonitoring = healthMonitoringFields[flow] || []

  return (
    <Section
      title={`Health Survey`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            {healthMonitoring.map((field) => {
              let followUpAnswer = _.get(data, field.followUpAnswerKey)
              let value = _.get(data, field.key)
              let finalValue = formatString(value)
              if (followUpAnswer && value.includes(field.referenceAnswer)) {
                finalValue = `${finalValue}, ${field.referenceAnswer}: ${followUpAnswer}`
              }
              finalValue = finalValue.replace(`${field.referenceAnswer},`, "")

              return (
                <tr>
                  <td className="has-text-weight-bold">{field.label} </td>
                  <td className={classNames(styles["table__data"])}>
                    {finalValue || "N/A"}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default HealthSurveySummary
