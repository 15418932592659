import React from "react"
import classNames from "classnames"
import _ from "lodash"

import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"

import styles from "./utils/summary.module.scss"
import personalInformationFields from "./utils/personalInformationFields"

/**
 *
 * @param {String} flow => medensure_request, medensure_pickup, ...
 */
const PersonalInformationSummary = ({ route, data }) => {
  const medEnsureReferralSources = data?.medEnsureReferralSources
  let meReferralString = ""
  if (
    Array.isArray(medEnsureReferralSources) &&
    medEnsureReferralSources?.length > 0
  ) {
    meReferralString = medEnsureReferralSources?.join(", ")
  }

  return (
    <Section
      title={`Personal Details`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            {personalInformationFields.map((field) => (
              <tr>
                <td className="has-text-weight-bold">{field.label}</td>
                <td className={classNames(styles["table__data"])}>
                  {field.keys
                    .map((key) =>
                      _.get(data, key) !== "" ? _.get(data, key) : "N/A"
                    )
                    .join(" ")}
                </td>
              </tr>
            ))}
            <tr>
              <td className="has-text-weight-bold">Site Name</td>
              <td className={classNames(styles["table__data"])}>
                {`${data?.siteName?.value}`}
              </td>
            </tr>
            {data?.deliveryAddress?.addressType === "Home" ? (
              <tr>
                <td className="has-text-weight-bold">Delivery Address</td>
                <td className={classNames(styles["table__data"])}>
                  {data?.deliveryAddress?.houseNumber
                    ? `${data?.deliveryAddress?.houseNumber} `
                    : ""}
                  {data?.deliveryAddress?.streetAddress},{" "}
                  {data?.deliveryAddress?.city?.value},{" "}
                  {data?.deliveryAddress?.province?.value}
                </td>
              </tr>
            ) : null}
            {data?.nps ? (
              <tr>
                <td className="has-text-weight-bold">Customer Feedback</td>
                <td className={classNames(styles["table__data"])}>
                  {`${data?.nps}`}
                </td>
              </tr>
            ) : null}
            <tr>
              <td className="has-text-weight-bold">
                MedEnsure Referral Sources
              </td>
              <td className={classNames(styles["table__data"])}>
                {meReferralString}
              </td>
            </tr>
            {data?.otherMedEnsureReferralSources ? (
              <tr>
                <td className="has-text-weight-bold">
                  Other MedEnsure Referral Sources
                </td>
                <td className={classNames(styles["table__data"])}>
                  {`${data?.otherMedEnsureReferralSources}`}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default PersonalInformationSummary
