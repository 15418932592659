import React from "react"

import Message from "elements/Message"
import Section from "elements/Section"
import EditDetailsButton from "elements/EditDetailsButton"
import FileThumbnail from "./UploadedDocumentsSummary/FileThumbnail"

const UploadDocumentsSummary = ({ route, documents }) => {
  return (
    <Section
      title={`Prescriptions Uploaded`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      {documents?.length > 0 ? (
        <div className="px-1 mx-1" tabIndex={0} role="button">
          <div className="columns is-vcentered is-multiline">
            {documents?.map((document) => {
              const arr = document?.oldname?.split(".")
              const filetype = arr[arr.length - 1]
              const isPdf = filetype === "pdf"
              return (
                <div className="column is-6">
                  <FileThumbnail file={document} isPdf={isPdf} />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <Message color="warning">
          <p className="has-text-black">You have not uploaded any documents.</p>
        </Message>
      )}
    </Section>
  )
}

export default UploadDocumentsSummary
